<template lang="html">
  <div v-if="showSection" class="cards">
    <div class="cards__heading" v-if="initialData.heading">
      <Heading :text="initialData.heading" :hType="1" />
    </div>
    <div
      class="cards__items gray-background"
      v-if="initialData.cards && initialData.cards.length"
    >
      <template v-for="(card, index) in initialData.cards" :key="index">
        <div
          class="cards__item"
          v-if="
            ($store.state.global.inHouse && card.hide === 'yes') ||
            card.hide !== 'yes'
          "
        >
          <router-link
            v-if="card.link"
            :to="card.link.url"
            @click="clickByCard(card.link.url)"
          >
            <div class="cards__item-banner">
              <div v-if="card.heading" class="cards__item-heading">
                <Heading :text="card.heading" :hType="2" />
              </div>
              <div
                v-if="card.description"
                class="cards__item-description"
                v-html="card.description"
              ></div>
              <div
                v-if="!$store.state.global.isMobile && card.desktop_button"
                class="cards__item-desktop-button"
              >
                {{ card.desktop_button }}
              </div>
            </div>
            <div v-if="card.picture" class="cards__item-picture">
              <ImageInContainer
                :src="card.picture.sizes.medium_large"
                :alt="card.picture.alt"
                :scale="`${(200 / 335) * 100}%`"
              />
            </div>
          </router-link>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import ImageInContainer from '@/components/ImageInContainer/ImageInContainer.vue';
import Heading from '@/components/Heading/Heading.vue';
import { heightByMaxHeight } from '@/utils';

export default {
  name: 'Cards',
  components: {
    ImageInContainer,
    Heading,
  },
  props: {
    initialData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    showSection() {
      return (
        (this.$store.state.global.inHouse &&
          this.initialData.in_house_intro === 'yes' &&
          this.$store.state.global.inHouseIntroScreen) ||
        (this.$store.state.global.inHouse &&
          this.initialData.in_house_intro === 'no' &&
          !this.$store.state.global.inHouseIntroScreen) ||
        (!this.$store.state.global.inHouse &&
          this.initialData.in_house_intro === 'no')
      );
    },
  },
  mounted() {
    this.heightForCards();
  },
  methods: {
    heightForCards() {
      if (!this.$store.state.global.isMobile) {
        const banners = document.querySelectorAll('.cards__item-banner');
        heightByMaxHeight(banners);
      }
    },
    clickByCard(url) {
      if (url === '/') {
        this.$store.commit('global/setInHouseIntroScreen', false);

        this.$nextTick(() => {
          this.heightForCards();
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'styles';
</style>
