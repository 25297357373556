<template lang="html">
  <div class="image-in-container">
    <div class="image-in-container__wrapper" :style="styleContainer">
      <img :src="src" :alt="alt" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImageInContainer',
  props: {
    src: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      required: true,
    },
    scale: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      styleContainer: {
        'padding-bottom': this.scale,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
@import 'styles';
</style>
